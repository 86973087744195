export const localeToHreflang = locale => {
    const [lang, region] = locale.toLowerCase().split('_');

    return !region || lang === region ? lang : `${lang}-${region}`;
};

export const toHreflangLink = (locale, href) => ({
    rel: 'alternate',
    hreflang: localeToHreflang(locale),
    href,
});
