import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

export default async (app = null, indexes = [], locale = '', currency = '') => {
    const { items = [] } = await app.$services.product.getByIndexes({
        indexes,
        limit: indexes.length,
        page: 1,
        locale,
        selectLocales: [DEFAULT_LOCALE],
        currency,
    });

    return items.reduce((acc, searchProduct) => {
        acc[searchProduct.id] = searchProduct;

        return acc;
    }, {});
};
