import { removeTrailingSlashes } from '@assets/url';

export const getUrlForSeomatic = (req, route) =>
    `https://${process.server ? req.headers.host : window.location.host}${
        route.path
    }`;

export const makeSeomaticRedirect = (seomaticRedirect, redirect, route) => {
    const { response_code = null, url = null } = seomaticRedirect || {};

    if (!url || !response_code) {
        throw new Error('Invalid seomatic redirect');
    }

    const urlWithQueryParams = new URL(url);

    Object.entries(route.query).forEach(([key, value]) => {
        urlWithQueryParams.searchParams.set(key, value);
    });

    return redirect(
        response_code,
        removeTrailingSlashes(urlWithQueryParams.toString())
    );
};
