import {
    GTM_CATEGORIES_STORAGE_KEY,
    GTM_PROVIDERS_STORAGE_KEY,
    CONSENTS_ITEMS_KEY,
    GTM_CONSENTS_ACCEPTED_KEY,
} from '@configs/storage';

import {
    CONSENTS_ACCEPTED,
    CONSENTS_NOT_ACCEPTED,
} from '@analytics-types/Analytics';

export default class GTMExcluded {
    constructor(storageClient) {
        this.storageClient = storageClient;
    }

    static analyticsFormatData(items) {
        return items
            .filter(({ isActive }) => !isActive)
            .map(({ code }) => code)
            .join('|');
    }

    getItem(key) {
        return this.storageClient?.getItem(key);
    }

    setItem(key, value) {
        return this.storageClient?.setItem(key, value);
    }

    getCategoriesAnalyticsData() {
        const categoriesData = this.getItem(GTM_CATEGORIES_STORAGE_KEY) || [];

        return GTMExcluded.analyticsFormatData(categoriesData);
    }

    getProvidersAnalyticsData() {
        const providersData = this.getItem(GTM_PROVIDERS_STORAGE_KEY) || [];

        return GTMExcluded.analyticsFormatData(providersData);
    }

    setAreConsentsAccepted() {
        const areConsentsAcceptedInStorage =
            this.getItem(GTM_CONSENTS_ACCEPTED_KEY) !== CONSENTS_NOT_ACCEPTED;
        const consentsCodes = this.getItem(CONSENTS_ITEMS_KEY);
        const areConsentsAccepted =
            areConsentsAcceptedInStorage && !!consentsCodes
                ? CONSENTS_ACCEPTED
                : CONSENTS_NOT_ACCEPTED;

        this.setItem(GTM_CONSENTS_ACCEPTED_KEY, areConsentsAccepted);

        return areConsentsAccepted;
    }
}
